import './education.css';
function Education({}){
    return(
        <div id='education-div'>
            <div id='education-content'>
                <div className='education-item'>
                    <div className='education-year'>2023.3 - ing </div>
                    <div className='education-description'>
                        <div className='education-major'>M.S. in KAIST School of Computing</div>
                        <div className='education-description'>Advisor: Prof. Geehyuk Lee</div>
                    </div>
                </div>
                <div className='education-item'>
                    <div className='education-year'>2018.3 - 2023.2</div>
                    <div className='education-description'>
                        <div className='education-major'>B.S. in KAIST School of Computing (Minor in Industrial Design)</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Education;