const projects = [
    {name : "YOLY",
        subtitle: "You Only Look at Yourself : Real-time Visual and Haptic Guidance System for Golf Swing in Virtual Reality",
        description:"2023 KAIST Graduate School of Culture Technology - Augmented Human",
        category: "Team Project",
        awards : "Best Augmented Humans Project (Runner-up)",
        teaserimg: "yoly.jpg",
        hashtag: ["HW", "Haptic", "VR"],
    youtube: "F4O0P7j7Nj4"},
    {name : "Handgrip",
    subtitle: "Haptic Direction Indicator Grips for Navigating Cyclists",
    description: "2022 School of Computing - Human Computer Interaction",
    category: "Team Project",
    teaserimg: "handgrip.png",
    hashtag: ["HW", "Haptic", "Mobility"],
    youtube : "DaxIBhCXJ5M"},
    {
        name: "Green Bread",
        subtitle: "Bakery-to-Consumer service that allows you to buy bread cheaply at the bakery's closing time",
        description: "KAIST SW Education Center 2nd App Startup Program (2기 App 창업 프로그램)",
        teaserimg: "greenbread.png",
        hashtag: ["SW", "Mobile App"],
        img: "greenbread.png",
        github: "https://github.com/yimjisu/GreenBreadProject",
        youtube: "kJXnlbqIH5w",
        category: "Team Project"
    },
    {name : "VVMarket", 
    subtitle: "Trading second-hand platform for low vision people",
    description:"2022 EPFL - Interaction Design",
    hashtag : ["SW", "Mobile App", "Accessibility"],
    category: "Team Project",
    teaserimg: "vvmarket.png",
    youtube : "uO2BKNnePSo"},
    {name : "Sketch Parrot", 
        subtitle: "Learn how to draw by following good drawing", 
        description:"2021 KAIST Industrial Design - Interactive Software Design", 
        category: "Team Project",
        teaserimg: "sketchparrot.png",
        hashtag : ["SW", "Education"],
        youtube : "GZUo94Nf4eo"},
    {name : "Color Catcher", 
        subtitle : "Playful activity for kids",
        description : "2021 KAIST Industrial Design - Interactive Product Design*",
        teaserimg: "color_catcher.jpg",
        hashtag : ["HW", "Fabrication", "Sensor"],
        youtube : "4_DiDJI3ny0"
    },
    {name : "FolDIY",
     subtitle : "Hanger for tidy closet",
     description : "2021 KAIST Industrial Design - Product Design",
     category: "Team Project",
     teaserimg: "foldiy.png",
    hashtag : ["HW", "Product Design"],
    youtube : "2RgsCyXRFdI"}
]

export default projects;