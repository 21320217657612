import './footer.css';
function Footer() {
  return ( 
  <footer className="footer-div">
  {/* <!--Copyright section--> */}
  <div
    className="div2">
    © Designed by Jisu Yim
  </div>
</footer>
  );
}

export default Footer;