import { Link } from 'react-router-dom';
import './menu.css';
import React, { useState, useEffect, useRef } from 'react';

function Menu({ref}) {
    const [open, setOpen] = useState(false);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e)=>{
      if(!menuRef.current.contains(e.target)){
        setOpen(false);
      }      
    };

    document.addEventListener("mousedown", handler);
    

    return() =>{
      document.removeEventListener("mousedown", handler);
    }

  });

    return (
        <div className="menu-div">

            <div className='menu-container' ref={menuRef}>
              <Link id="myname" to ="/">Jisu Yim</Link>
            {/* <div className={`menu-trigger ${open? 'active' : 'inactive'}`} onClick={()=>{setOpen(!open)}}> */}
            {/* <img src="/figure/list.png"></img> */}
            {/* </div> */}
            {/* <div className={`dropdown-menu ${open? 'active' : 'inactive'}`} >
            <ul>
                <DropdownItem text = {"Publication"} id="publication"/>
                <DropdownItem text = {"Fun Project"}id="funproject"/>
                <DropdownItem text = {"Education"}id="education"/>
            </ul>
            </div> */}
            </div>
        </div>
    );
}


function DropdownItem(props){
  
  const handleClickScroll = () => {
    const element = document.getElementById(props.id);
    const offset = 60;
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      //element.scrollIntoView({ behavior: 'smooth', block: 'start', top: 50});
      window.scrollTo({
        behavior: 'smooth',
        top:
          element.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          offset,
      })
    }


  };


    return(
      <li className = 'dropdownItem' onClick={handleClickScroll}>
        <a> {props.text} </a>
      </li>
    );
  }

export default Menu;