function Author({author, idx}) { 
 return (
    <>
        {idx > 0 && 
        <p style={{marginRight: '5px'}}>,</p>}
        {author == 'Jisu Yim' && <p className="isMe">Jisu Yim</p>}
        {author != 'Jisu Yim' && <p>{author}</p>}
    </>
 )
}

export default Author;