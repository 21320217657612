import './publication.css';
import Paper from './paper';
import papers from './papers';
function Publication({ref}){
   
    return(
        <div className='publication-container'>
        {
            papers.map((paper) => {
                return (
                    <Paper data={paper} key={paper.name}/>
                )
            })
        }
        </div>
    )
}
export default Publication;