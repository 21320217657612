import Education from '../education/education';
import Project from '../project/project';
import Publication from '../publication/publication';
import './body.css';
function Body(){
    return(
        <div id='body-div'>
            <div  id="publication">
                <h1>Publication</h1>
                <Publication/>
            </div>
            <div  id="funproject">
                <h1>Fun Project</h1>
                <Project/>
            </div>
            <div id="education">
                <h1>Education</h1>
                <Education/>
            </div>
        </div>
    )
}
export default Body;