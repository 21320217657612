import Header from './header/header';
import Menu from './menu/menu';
import Body from './body/body';
import Footer from './footer/footer';
import './App.css';
import {useEffect, useState, useRef} from 'react';

function App() {
  
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    const handleScroll = event => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App" >
      <header className="App-header">
        
      </header>
      <div className="Body">
      <Menu/>
      <div className='main-content'>
        <Header scrollTop={scrollTop} width = {windowSize.width}/>
        <Body/>
      </div>
      <Footer/>
      </div>
    </div>
  );
}

export default App;
