
function Hex({project}) {
    return (
        <div className = "content">
            <img src={'/figure/project/'+project.teaserimg}/>
            <p>{project.name}</p>
        </div>
    )
}

export default Hex;