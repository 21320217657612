const papers = [
    {
        name: "Palmrest+: Expanding Laptop Input Space with Shear Force on Palm-Resting Area",
        video : "",
        authors : ['Jisu Yim', 'Seoyeon Bae', 'Taejun Kim', 'Sunbum Kim', 'Geehyuk Lee'],
        where: "UIST 2024",
        img: "palmrest.png",
        pdf: "palmrest.pdf",
        abstract: "The palmrest area of laptops has the potential as an additional input space, considering its consistent palm contact during keyboard interaction. We propose Palmrest+, leveraging shear force exerted on the palmrest area. We suggest two input techniques: Palmrest Shortcut, for instant shortcut execution, and Palmrest Joystick, for continuous value input. These allow seamless and subtle input amidst keyboard typing. Evaluation of Palmrest Shortcut against conventional keyboard shortcuts revealed faster performance for applying shear force in unimanual and bimanual-manner with a significant reduction in gaze shifting. Additionally, the assessment of Palmrest Joystick against the laptop touchpad demonstrated comparable performance in selecting one- and two- dimensional targets with low-precision pointing, i.e., for short distances and large target sizes. The maximal hand displacement significantly decreased for both Palmrest Shortcut and Palmrest Joystick compared to conventional methods. These findings verify the feasibility and effectiveness of leveraging the palmrest area as an additional input space on laptops, offering promising enhanced typing-related user interaction experiences.",
        video: "https://www.youtube.com/watch?v=9ipT5We16CY",
        url: "https://dl.acm.org/doi/10.1145/3654777.3676371",
    },{
        name: "Pro-Tact: Hierarchical Synthesis of Proprioception and Tactile Exploration for Eyes-Free Ray Pointing on Out-of-View VR Menus",
        video : "",
        authors : ['Yeonsu Kim', 'Jisu Yim', 'Kyunghwan Kim', 'Yohan Yun', 'Geehyuk Lee'],
        where: "UIST 2024",
        img: "protact.png",
        pdf: "protact.pdf",
        url: "https://dl.acm.org/doi/10.1145/3654777.3676324",
        video: "https://www.youtube.com/watch?v=faEnWDRHs_s"
    },
    {
        name: "STButton: Exploring Opportunities for Buttons with Spatio-Temporal Tactile Output",
        url: "https://dl.acm.org/doi/10.1145/3613905.3648671",
        video : "https://youtu.be/pZJk8ueLfsY?feature=shared",
        authors : ['Yeonsu Kim', 'Jisu Yim', 'Jaehyun Kim', 'Kyunghwan Kim', 'Geehyuk Lee'],
        where: "CHI 2024 Interactivity (Demo)",
        img : "stbutton.png",
        tag : "Demo",
        award : "Jury Honorable Mention Award",
        pdf: "stbutton.pdf"
    },
    {
        name: "HapticPalmrest: Haptic Feedback through the Palm for the Laptop Keyboard",
        url: "https://dl.acm.org/doi/abs/10.1145/3544549.3585663",
        video : "https://youtu.be/RbxXK9G6c5I?feature=shared",
        authors : ['Jisu Yim', 'Sangyoon Lee', 'Geehyuk Lee'],
        where: "CHI 2023 Late-Breaking Work (Poster)",
        img : "hapticpalmrest.png",
        pdf: "hapticpalmrest.pdf",
        projectpage: "hapticpalmrest",
        abstract: "Programmable haptic feedback on touchscreen keyboards enriches user experiences but is hard to realize for physical keyboards because this requires individually augmenting each key with an actuator. As an alternative approach, we propose HapticPalmrest, where haptic feedback for a physical keyboard is provided to the palms. This is particularly feasible for a laptop environment, where users usually rest their palms while interacting with the keyboard. To verify the feasibility of the approach, we conducted two user studies. The first study showed that at least one palm was on palmrest for more than 90% of key interaction time. The second study showed a vibration power of 1.17 g (peak-to-peak) and a duration of 4 ms was sufficient for reliable perception of palmrest vibrations during keyboard interaction. We finally demonstrated the potential of such an approach by designing Dwell+ Key, an application that extends the function of each key by enabling timed dwelling operations."
    },
    {
        name: "CatchLive: Real-time Summarization of Live Streams with Stream Content and Interaction Data",
        url: "https://doi.org/10.1145/3491102.3517461",
        authors : ['Saelyne Yang', 'Jisu Yim', 'Juho Kim', 'Hijung Valentina Shin'],
        where: "CHI 2022",
        page : "https://catchlive.kixlab.org/",
        img : "catchlive.png",
        pdf: "catchlive.pdf"
    },
    {
        name: "SoftVideo: Improving the Learning Experience of Software Tutorial Videos with Collective Interaction Data",
        url: "https://doi.org/10.1145/3490099.3511106",
        authors : ['Saelyne Yang', 'Jisu Yim', 'Aitolkyn Baigutanova', 'Seoyoung Kim', 'Minsuk Chang', 'Juho Kim'],
        where: "IUI 2022",
        page : "https://softvideo.kixlab.org/",
        img : "softvideo.png",
        pdf: "softvideo.pdf"
    }
]

export default papers;