import Author from './author'
import './publication.css'
import { FaAward } from "react-icons/fa6";
import {Link} from 'react-router-dom'
function Paper({data}){
    return(  
        <div className='publication-div' id={data.name}>
            <div className='publication-content-div'>
                <h3>{data.name} </h3>
                <div className='author-div'>
                    {
                        data.authors.map((author, idx) => {
                            return <Author key={idx} author={author} idx={idx}/>
                        })
                    }
                </div>
                <p className="where">{data.where}</p>
                { data.award && <div className="award">
                    <FaAward/>
                    <p >{data.award}</p>
                </div>}
                <div className='url-div'>
                { data.url && <a href={data.url}>DOI</a>}
                { data.pdf && <a href={"/figure/publication/"+data.pdf}>Paper</a>}
                { data.video && <a href={data.video}>Video</a>}
                { data.page && <a href={data.page}>Page</a>}
                { data.projectpage && <Link to={"/"+data.projectpage}>Page</Link> }
                </div>
            </div>
            <div className='publication-img-div'>
            <img src={"/figure/publication/"+data.img} alt="img"/>
            </div>
        </div>
    )
}
export default Paper;