import React, { useEffect, useState } from 'react';
import './header.css';
import News from "./news";
import Description from "./description";
import Profile from "./profile";

function Header({ scrollTop, width }) {
  return ( 
    <div className='header-div'>
      <div className='header-title'>
        <Profile scrollTop={scrollTop} />
        <div className = 'header-content'>
          <Description />
          <News />
        </div>
      </div>
      </div>
  );
}

export default Header;