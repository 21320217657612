import Menu from '../menu/menu';
import Author from './author'
import './projectpage.css'
import { FaAward } from "react-icons/fa6";
function ProjectPage({data}){
    return(  
        <>
        
        <Menu/>
        
        <div className='page-div'>
            <div className='page-content-div'>
                <h3>{data.name} </h3>
                <div className='author-div'>
                    {
                        data.authors.map((author, idx) => {
                            return <Author key={idx} author={author} idx={idx}/>
                        })
                    }
                </div>
                <p className="where">{data.where}</p>
                { data.award && <div className="award">
                    <FaAward/>
                    <p >{data.award}</p>
                </div>}
                <div className='url-div'>
                {data.url && <a href={data.url}>DOI</a>}
                {data.pdf && <a href={"/figure/publication/"+data.pdf}>Paper</a>}
                { data.video && <a href={data.video}>Video</a>}
                { data.page && <a href={data.page}>Page</a> }
                </div>
            </div>
            <div className='page-img-div'>
            <img src={"/figure/publication/"+data.img} alt="img"/>
            </div>
            <div className='page-abstract'>
                {data.abstract}
            </div>

            <div className='page-video'>
            {      
                data.youtube ?
                <iframe src={"https://www.youtube.com/embed/"+data.youtube+"?autoplay=1&mute=1"}></iframe>
                : (
                    data.video && 
                    <video controls>
                        <source src={"/figure/project/"+data.video} type="video/mp4"/>
                    </video>
                )
            }
            </div>
        </div>
        </>
    )
}
export default ProjectPage;