//Router.js
import React from 'react';
// 1. react-router-dom을 사용하기 위해서 아래 API들을 import 합니다.
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import ProjectPage from './publication/projectpage';
import papers from './publication/papers';

// 2. Router 라는 함수를 만들고 아래와 같이 작성합니다.
//BrowserRouter를 Router로 감싸는 이유는,
//SPA의 장점인 브라우저가 깜빡이지 않고 다른 페이지로 이동할 수 있게 만들어줍니다!
const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
            <Route path="/" element={<App/>} />
            {
                papers.map((data, idx) => {
                    if (!data.projectpage) return null;
                    return <Route key={idx} 
                    path={"/"+data.projectpage} 
                    element={<ProjectPage data={data}/>}>
                    </Route>
                })
            }
            </Routes>
        </BrowserRouter>
    );
};

export default Router;